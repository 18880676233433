<template>
  <div class="initials-avatar">
    <b-avatar class="user-select-none"
              :text="text"
              :variant="variant"
              :title="title"
              v-bind="$attrs" />
  </div>
</template>

<script>
  import { BAvatar } from 'bootstrap-vue'

  const variants = ['light-primary', 'light-secondary', 'light-success', 'light-danger', 'light-warning', 'light-info']

  export default {
    name: 'InitialsAvatar',
    components: {
      BAvatar,
    },
    inheritAttrs: false,
    props: {
      familyName: {
        type: String,
        required: true,
        default: '',
      },
      givenName: {
        type: String,
        required: true,
        default: '',
      },
      showTitle: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      name() {
        return `${this.familyName.toLowerCase()}${this.givenName.toLowerCase()}`
      },
      text() {
        return `${this.familyName.charAt(0).toUpperCase()}${this.givenName.charAt(0).toUpperCase()}`
      },
      title() {
        return this.showTitle ? `${this.familyName} ${this.givenName}` : null
      },
      variant() {
        let hash = 0
        for (let i = 0; i < this.name.length; i += 1) {
          hash = ((hash << 5) - hash) + this.name.charCodeAt(i) // eslint-disable-line
          hash &= hash // eslint-disable-line
        }
        return variants[Math.abs(hash) % variants.length]
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-extended/include';
  @import '~@core/scss/base/components/variables-dark';

  .initials-avatar {
    background: #fff;
    border-radius: 50% !important;
  }

  .dark-layout {

    .initials-avatar {
      background: $theme-dark-body-bg;

      .b-avatar .b-avatar-text span {
        color: inherit !important;
      }
    }
  }
</style>
