import Vue from 'vue'
import axiosIns from '@/libs/axiosIns'

export default {
  namespaced: true,
  state: {
    users: [],
    historyCall: [],
    callRecording: [],
    userRole: '',
    currentUser: { lastName: '', firstName: '' },
  },
  getters: {
    getUser: state => id => state.users.find(user => user.id === id),
    users: state => state.users,
    getHistoryCall: state => state.historyCall,
    getCallRecording: state => state.callRecording,
    getUserRole: state => ((typeof state.userRole === 'string') ? state.userRole : state.userRole.find(e => e.startsWith('LeRefuge'))),
    currentUser: state => {
      const { currentUser } = state
      if (currentUser) {
        return {
          ...currentUser,
          name: `${currentUser.lastName} ${currentUser.firstName}`,
        }
      }

      return currentUser
    },
  },
  mutations: {
    fetchUser(state, users) {
      state.users = users
    },
    fetchHistoryCall(state, historyCall) {
      state.historyCall = state.historyCall.concat(historyCall)
    },
    resetHistoryCall(state) {
      state.historyCall = []
    },
    fetchCallRecording(state, callRecording) {
      state.callRecording = callRecording
    },
    setRole(state, role) {
      state.userRole = role
    },
    addUserMutation(state, user) {
      state.users = [...state.users, user]
    },
    updateUsersForm(state, users) {
      const index = state.users.findIndex(e => e.id === users.id)
      Vue.set(state.users, index, users)
    },
    updateListenBy(state, historyCallUpdated) {
      const listenBy = {
        userFirstName: historyCallUpdated.userFirstName,
        userLastName: historyCallUpdated.userLastName,
        userId: historyCallUpdated.userId,
        date: historyCallUpdated.date,
      }
      const index = state.historyCall.findIndex(e => e.id === historyCallUpdated.callId)
      state.historyCall[index].listenBys.push(listenBy)
    },
    deleteCallVoicemail(state, id) {
      const index = state.historyCall.findIndex(e => e.id === id)
      state.historyCall.splice(index, 1)
    },
    setCurrentUser(state, user) {
      state.currentUser = user
    },
    deleteUser(state, id) {
      const index = state.users.findIndex(e => e.id === id)
      state.users.splice(index, 1)
    },
  },
  actions: {
    fetchUsers({ commit }) {
      return new Promise((resolve, reject) => {
        axiosIns.get('User')
          .then(response => {
            commit('fetchUser', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchHistoryCall({ commit }, data) {
      return new Promise((resolve, reject) => {
        if (data.PageNumber === 1) { commit('resetHistoryCall') }
        axiosIns.get(`User/CallVoicemail?PageNumber=${data.PageNumber}&PageSize=${data.PageSize}`)
          .then(response => {
            commit('fetchHistoryCall', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchCallRecording({ commit }) {
      return new Promise((resolve, reject) => {
        axiosIns.get('User/CallRecordings')
          .then(response => {
            commit('fetchCallRecording', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        axiosIns.put('User/Update', data)
          .then(response => {
            commit('updateUsersForm', data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateListenBy({ commit }, data) {
      return new Promise((resolve, reject) => {
        axiosIns.put('User/UpdateListenBy', data)
          .then(response => {
            commit('updateListenBy', data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    deleteCallVoicemail({ commit }, id) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`User/DeleteCallVoicemail/${id}`)
          .then(response => {
            commit('deleteCallVoicemail', id)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    anonymizeYoung({ commit }, youngId) {
      return new Promise((resolve, reject) => {
        axiosIns.put(`User/AnonymizeYoung/${youngId}`)
          .then(response => {
            commit('youngs/updateYoung', response.data, { root: true })
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getUserAndSetStateById({ commit }, userId) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`User/${userId}`)
          .then(response => {
            commit('setCurrentUser', response.data)
            commit('setRole', response.data.roles[0])
            resolve(response)
            })
          .catch(error => reject(error))
      })
    },
    getOrInsertUserById({ getters, commit }, userId) {
      const user = getters.getUser(userId)
      if (user) {
        return Promise.resolve(user)
      }
      return new Promise((resolve, reject) => {
        axiosIns.get(`User/${userId}`)
          .then(response => {
            commit('addUserMutation', response.data)
            resolve(response.data)
          })
        .catch(error => reject(error))
      })
    },
    resetPassword(_, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('User/ResetPassword', data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
      })
    },
    forceResetPassword(_, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('User/ForceResetPassword', data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
      })
    },
    deleteUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`User/${id}`)
          .then(response => {
            commit('deleteUser', id)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
