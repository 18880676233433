<template>
  <div>
    <div class="personal-info">
      <h6 class="section-label mb-1">
        Informations personnelles
      </h6>
      <ul class="list-unstyled m-0">
        <li class="d-flex align-items-center mb-2">
          <feather-icon icon="UserIcon" size="16" class="flex-shrink-0 mr-1" />
          <b-form-input v-model="updatedYoung.firstName" type="text" placeholder="Entrez un prénom" />
        </li>
        <li class="d-flex align-items-center mb-2">
          <feather-icon icon="UserIcon" size="16" class="flex-shrink-0 mr-1" />
          <b-form-input v-model="updatedYoung.lastName" type="text" placeholder="Entrez un nom" />
        </li>
        <li class="d-flex align-items-center mb-2">
          <feather-icon icon="SmartphoneIcon" size="16" class="flex-shrink-0 mr-1" />
          <span>{{ formatPhoneNumber(young.phoneNumber) }}</span>
        </li>
        <li class="d-flex align-items-center mb-2">
          <feather-icon icon="CalendarIcon" size="16" class="flex-shrink-0 mr-1" />
          <b-form-select v-model="updatedYoung.ageRange" class="ml-1">
            <option disabled>
              Tranche d'âge
            </option>
            <option v-for="opt in ageRangeOptions" :key="opt.label" :value="opt.id">
              {{ opt.label }}
            </option>
          </b-form-select>
        </li>
        <li class="d-flex align-items-center mb-2">
          <feather-icon icon="MailIcon" size="16" class="flex-shrink-0 mr-1" />
          <b-form-input v-model="updatedYoung.email" type="email" placeholder="Entrez un email" />
        </li>
        <li class="d-flex align-items-center mb-2">
          <feather-icon icon="UserIcon" size="16" class="flex-shrink-0 mr-1" />
          <b-form-select v-model="updatedYoung.gender" class="ml-1">
            <option v-for="opt in genderOptions" :key="opt.label" :value="opt.id">
              {{ opt.label }}
            </option>
          </b-form-select>
        </li>
        <li class=" mb-2">
          <feather-icon icon="MapPinIcon" size="16" class="flex-shrink-0 mr-1 mb-2" />
          <b-form-input v-model="updatedYoung.address" type="text" placeholder="Entrez une adresse" />
          <b-form-input v-model="updatedYoung.zipCode" type="text" placeholder="Entrez un code postal" class="my-2" />
          <b-form-input v-model="updatedYoung.city" type="text" placeholder="Entrez une ville" />
        </li>
        <li class="d-flex align-items-center mb-2">
          <feather-icon icon="MapIcon" size="16" class="flex-shrink-0 mr-1" />
          <b-form-select v-model="updatedYoung.department" class="ml-1">
            <option disabled>
              Département
            </option>
            <option value="0">
              Non renseigné
            </option>
            <option v-for="opt in departmentOptions" :key="opt.id" :value="String(opt.id)">
              {{ opt.label }}
            </option>
          </b-form-select>
        </li>
      </ul>
    </div>
    <div>
      <h6 class="section-label mb-1">
        Demande
      </h6>
      <ul class="list-unstyled m-0">
        <li class="d-flex align-items-center mb-2" title="Type de demande">
          <feather-icon icon="HelpCircleIcon" size="16" class="flex-shrink-0 mr-1" />
          <b-form-select v-model="updatedYoung.demandType" class="ml-1">
            <option disabled>
              Type de demande
            </option>
            <option value="0">
              Non renseigné
            </option>
            <option v-for="opt in demandTypeOptions" :key="opt.label" :value="opt.id">
              {{ opt.label }}
            </option>
          </b-form-select>
        </li>
        <li class="d-flex align-items-center mb-2" title="Raison de la demande">
          <feather-icon icon="ClipboardIcon" size="16" class="flex-shrink-0 mr-1" />
          <b-form-select v-model="updatedYoung.demandReason" class="ml-1">
            <option disabled>
              Raison de la demande
            </option>
            <option value="0">
              Non renseigné
            </option>
            <option v-for="opt in demandReasonOptions" :key="opt.label" :value="opt.id">
              {{ opt.label }}
            </option>
          </b-form-select>
        </li>
      </ul>
    </div>
    <div>
      <h6 class="section-label mb-1">
        Situation
      </h6>
      <ul class="list-unstyled m-0">
        <li class="d-flex align-items-center mb-2" title="Situation d'hébergement">
          <feather-icon icon="HomeIcon" size="16" class="flex-shrink-0 mr-1" />
          <b-form-select v-model="updatedYoung.situationOfAccommodation" class="ml-1">
            <option disabled>
              Situation d'hébergement
            </option>
            <option value="0">
              Non renseigné
            </option>
            <option v-for="opt in accomodationSituationOptions" :key="opt.label" :value="opt.id">
              {{ opt.label }}
            </option>
          </b-form-select>
        </li>
        <li class="d-flex align-items-center mb-2" title="Comment la personne a connu le Refuge">
          <feather-icon icon="InfoIcon" size="16" class="flex-shrink-0 mr-1" />
          <b-form-select v-model="updatedYoung.howThePersonGotToKnowUs" class="ml-1">
            <option disabled>
              Comment la personne a connu le Refuge
            </option>
            <option value="0">
              Non renseigné
            </option>
            <option v-for="opt in howThePersonGotToKnowUsOptions" :key="opt.label" :value="opt.id">
              {{ opt.label }}
            </option>
          </b-form-select>
        </li>
      </ul>
    </div>
    <div class="comment mb-2">
      <h6 class="section-label mb-1 mt-3">
        Observations
      </h6>
      <b-form-textarea v-model="updatedYoung.observation" rows="3" placeholder="Entrez une observation" />
    </div>
    <b-button variant="danger" class="mr-3" @click="onUpdateAction">
      Annuler
    </b-button>
    <b-button variant="outline-primary" @click="onValidate">
      Valider
    </b-button>
  </div>
</template>

<script>
import { formatDate, formatPhoneNumber } from '@core/utils/filter'
import {
  BFormInput, BFormSelect, BButton, BFormTextarea,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import store from '@/store'
import departements from '@/assets/departments.json'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormInput,
    BFormSelect,
    BButton,
    BFormTextarea,
  },
  props: {
    young: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      formatDate,
      departements,
      formatPhoneNumber,
    }
  },
  data() {
    return {
      updatedYoung: {
        ...this.young,
      },
    }
  },
  computed: {
    console() {
      return console
    },
    ...mapGetters({
      taxonomies: 'initialData/getTaxonomies',
    }),
    ageRangeOptions() {
      return this.taxonomies.ageRange
    },
    genderOptions() {
      return this.taxonomies.gender
    },
    demandTypeOptions() {
      return this.taxonomies.demandType
    },
    demandReasonOptions() {
      return this.taxonomies.demandReason
    },
    accomodationSituationOptions() {
      return this.taxonomies.situationOfAccommodation
    },
    howThePersonGotToKnowUsOptions() {
      return this.taxonomies.howThePersonGotToKnowUs
    },
    departmentOptions() {
      return this.departements.map(d => ({ id: d.num_dep, label: `${d.num_dep} ${d.dep_name}` }))
    },
    regionName() {
      return this.departements.find(d => String(d.num_dep) === String(this.young.department))?.region_name
    },
  },
  mounted() {
    if (this.isTaxonomiesEmpty()) {
      store.dispatch('initialData/fetchInitialData')
    }
  },
  methods: {
    displayToast(title, icon, mode) {
      this.toast({
        component: ToastificationContent,
        props: {
          title,
          position: 'bottom-right',
          icon,
          variant: mode,
        },
      })
    },
    onValidate() {
      this.updatedYoung.region = this.regionName
      store.dispatch('youngs/updateYoungDetails', this.updatedYoung)
        .then(() => {
          this.displayToast('Jeune modifié', 'CheckIcon', 'success')
          this.$emit('update-action')
        })
        .catch(error => {
          if (error.response && error.response.data && Array.isArray(error.response.data)) {
            error.response.data.forEach(errorItem => {
              if (errorItem.propertyName === 'FirstName') {
                this.displayToast(
                  'Le format du champ "FirstName" est incorrect.',
                  'XCircleIcon',
                  'danger',
                )
              }
              if (errorItem.propertyName === 'LastName') {
                this.displayToast(
                  'Le format du champ "LastName" est incorrect.',
                  'XCircleIcon',
                  'danger',
                )
              }
            })
          } else {
            this.displayToast(
              'Le format de l\'un des champs est incorrect. Veuillez vérifier et corriger.',
              'XCircleIcon',
              'danger',
            )
          }
        })
    },
    onUpdateAction() {
      this.$emit('update-action')
    },
    isTaxonomiesEmpty() {
      // Check if all taxonomy arrays in the object are empty
      return Object.values(this.taxonomies).every(arr => !arr || arr.length === 0)
    },
  },
}

</script>
