import axiosIns from '@axiosIns'
import axios from 'axios'
import Vue from 'vue'

let cancelTokenSource = null

const cancelPendingApiRequests = () => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel()
  }
  cancelTokenSource = axios.CancelToken.source()
}

export default {
  namespaced: true,
  state: {
    activeYoungId: null,
    messages: [],
    youngs: [],
    youngsComment: [],
    blockedBy: {},
    excelDatas: null,
    zipDatas: null,
  },
  getters: {
    activeYoung: (state, getters) => (state.activeYoungId
      ? getters.youngs.find(y => y.id === state.activeYoungId)
      : {}),
    messages: (state, getters, rootState, rootGetters) => state.messages
      .map(message => ({
        ...message,
        user: message.userId ? rootGetters['users/getUser'](message.userId) : null,
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date)),
    youngsComment: state => state.youngsComment,
    youngs: (state, getters, rootState, rootGetters) => state.youngs
      .map(young => ({
        ...young,
        lastMessageUser: young.lastMessageUserId ? rootGetters['users/getUser'](young.lastMessageUserId) : null,
      }))
      .sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate)),
    getBlockedBy: state => state.blockedBy,
    excelDatas: state => state.excelDatas,
    zipDatas: state => state.zipDatas,
  },
  mutations: {
    addOrUpdateMessage(state, message) {
      if (state.activeYoungId === message.youngId) {
        state.messages = state.messages
          .filter(m => m.id !== message.id)
          .concat([message])
      }
      const young = state.youngs.find(y => y.id === message.youngId)
      if (young) {
        Vue.set(young, 'lastMessageDate', message.date)
        if (message.userId && young.lastMessageUserId !== message.userId) {
          Vue.set(young, 'lastMessageUserId', message.userId)
        }
      }
    },
    addOrUpdateYoung(state, young) {
      state.youngs = state.youngs
        .filter(y => y.id !== young.id)
        .concat([young])
    },
    deleteMessage(state, id) {
      state.messages = state.messages.filter(m => m.id !== id)
    },
    deleteYoung(state, id) {
      if (state.activeYoungId === id) {
        state.activeYoungId = null
      }
      state.youngs = state.youngs.filter(y => y.id !== id)
      state.messages = state.messages.filter(m => m.youngId !== id)
    },
    setActiveYoungId(state, id) {
      state.activeYoungId = id
      state.messages = state.messages.filter(m => m.youngId === id)
    },
    updateMessages(state, messages) {
      const allMessages = state.messages.filter(m => m.youngId === state.activeYoungId).concat(messages)
      const distinctMessages = []
      const idMap = new Map()
      allMessages.forEach(message => {
        if (!idMap.has(message.id)) {
          idMap.set(message.id, true)
          distinctMessages.push(message)
        }
      })
      state.messages = distinctMessages
    },
    updateYoungs(state, youngs) {
      state.youngs = youngs
    },
    changeStatusToPending(state, id) {
      const foundIndex = state.youngs.findIndex(x => x.id === id)
      state.youngs[foundIndex].conversationStatus = 1
    },
    fetchedYoungComments(state, comments) {
      state.youngsComment = comments
    },
    updatedComment() { },
    updateYoung(state, updatedYoung) {
      state.youngs = state.youngs.map(young => (young.id === updatedYoung.id
        ? { ...young, ...updatedYoung }
        : young))
    },
    addedComment(state, comment) {
      state.youngsComment.push(comment)
    },
    fetchedBlockedBy(state, blockedBy) {
      state.blockedBy = blockedBy
    },
    updateExcelDatas(state, excelDatas) {
      state.excelDatas = excelDatas
    },
    updateZipDatas(state, zipDatas) {
      state.zipDatas = zipDatas
    },
  },
  actions: {
    addOrUpdateMessage({ commit }, message) {
      commit('addOrUpdateMessage', message)
    },
    addOrUpdateYoung({ commit }, young) {
      commit('addOrUpdateYoung', young)
    },
    deleteYoung({ commit }, youngId) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`Young/${youngId}`)
          .then(response => {
            commit('deleteYoung', youngId)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    setActiveYoungId({ commit }, youngId) {
      return new Promise((resolve, reject) => {
        cancelPendingApiRequests()
        commit('setActiveYoungId', youngId)
        axiosIns.get(`Young/${youngId}/Messages`, { cancelToken: cancelTokenSource.token })
          .then(response => {
            commit('updateMessages', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchYoungs({ commit }) {
      return new Promise((resolve, reject) => {
        cancelPendingApiRequests()
        axiosIns.get('Young', { cancelToken: cancelTokenSource.token })
          .then(response => {
            commit('updateYoungs', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    changeConversationStatus({ commit }, youngId) {
      return new Promise((resolve, reject) => {
        axiosIns.put(`Young/${youngId}/PendingConversation`)
          .then(response => {
            commit('changeStatusToPending', youngId)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchYoungComments({ commit }, youngId) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`Young/${youngId}/Comments`)
          .then(response => {
            commit('fetchedYoungComments', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateComment({ commit }, data) {
      const body = {
        comment: data.comment,
        commentId: data.commentId,
      }
      return new Promise((resolve, reject) => {
        axiosIns.put(`Young/${data.youngId}/UpdateComment`, body)
          .then(response => {
            commit('updatedComment', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addComment({ commit }, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`Young/${data.youngId}/AddComment`, data.comment, {
          headers: { 'Content-Type': 'application/json' },
        })
          .then(response => {
            commit('addedComment', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateYoungDetails({ commit }, data) {
      return new Promise((resolve, reject) => {
        axiosIns.put('Young/', data)
          .then(response => {
            commit('updateYoung', data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchBlockedBy({ commit }, youngId) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`Young/${youngId}/BlockedBy`)
          .then(response => {
            commit('fetchedBlockedBy', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchExcelDatas({ commit }, youngId) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`Young/${youngId}/ExportExcel`, {}, { responseType: 'blob' })
          .then(response => {
            commit('updateExcelDatas', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchZipDatas({ commit }, youngId) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`Young/${youngId}/ExportZip`, {}, { responseType: 'blob' })
          .then(response => {
            commit('updateZipDatas', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
